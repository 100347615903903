import React, { useMemo, useState } from 'react';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import { ActionIconButton, StyleColumnCell } from '../styled-components';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import RowSelector from './components/RowSelector';
import BatchAccountDeleteDialog from '../dialog/data-ingestion/BatchAccountDeleteDialog';
import IgnoreAccountDialog from '../../components/dialog/data-ingestion/IgnoreAccountDialog';
import StagingTransactionDialog from '../dialog/StagingTransactionDiolog';
import StagingInvestmentDialog from '../dialog/data-ingestion/StagingInvestmentDialog';
import { TableContainer } from './components/table-styled-components';
import StagingTreasuryServicesDialog from '../dialog/data-ingestion/StagingTreasuryServicesDialog';
import TransactionBulkInspectDialog from '../dialog/TransactionBulkInspectDialog';

const ValidateBatchTable = ({
  appUser,
  batchAccounts,
  accountHasFiles,
  handleValidateBatchAccount,
  checkIgnore,
  downloadSource,
  handleEnableAccountConfirmation,
  isBatchAccountDeleteDialogEnabled,
  selectedBatchAccount,
  handleCloseAccountConfirmation,
  handleDeleteBatchAccount,
  isDeletingBatchAccount,
  isIgnoreOpen,
  handleCloseIgnoreConfirmation,
  handleIgnoreBatchAccount,
  isIgnoring,
  handleRestoreBatchAccount,
  clientName,
  bankName,
  accountType,
  isInvestment,
  isTreasuryServices,
  isHybridAccount,
  batchId,
  ...props
}) => {
  const [isValidateTransactionOpen, setIsValidateTransactionOpen] =
    useState(false);
  const [isValidateInvestmentOpen, setIsValidateInvestmentOpen] =
    useState(false);
  const [isValidateTreasuryServiceOpen, setIsValidateTreasuryServiceOpen] =
    useState(false);
  const [batchAccount, setBatchAccount] = useState({});
  const [selectedBatchIds, setSelectedBatchIds] = useState([]);
  const [isServerSidePagination, setIsServerSidePagination] = useState(false);
  const [isBulkInspectDialogOpen, setIsBulkInspectDialogOpen] = useState(false);

  const handleCloseValidateTransaction = () => {
    setIsValidateTransactionOpen(false);
    setBatchAccount({});
    setIsServerSidePagination(false);
  };

  const handleCloseValidateInvestment = () => {
    setIsValidateInvestmentOpen(false);
    setBatchAccount({});
    setIsServerSidePagination(false);
  };

  const handleCloseValidateTreasuryServices = () => {
    setIsValidateTreasuryServiceOpen(false);
    setBatchAccount({});
    setIsServerSidePagination(false);
  };

  const handleStagingTransactionDialogOpen = (batchAccount) => {
    setBatchAccount(batchAccount);
    setIsValidateTransactionOpen(true);
    setIsServerSidePagination(true);
  };

  const handleStagingInvestmentDialogOpen = (batchAccount) => {
    setBatchAccount(batchAccount);
    setIsValidateInvestmentOpen(true);
    setIsServerSidePagination(true);
  };

  const handleStagingTreasuryServiceDialogOpen = (batchAccount) => {
    setBatchAccount(batchAccount);
    setIsValidateTreasuryServiceOpen(true);
  };

  const handleBulkInspectDialogOpen = (selectedRows) => {
    const extractedBatchIds = selectedRows
      .filter(
        (row) => row.status === 'Needs Validation' || row.status === 'Validated'
      )
      .map((row) => row.batchAccountId);
    setSelectedBatchIds(extractedBatchIds);
    if (extractedBatchIds.length) {
      setIsServerSidePagination(true);
      setIsBulkInspectDialogOpen(true);
    }
  };

  const handleCloseBulkInspectDialog = () => {
    setIsServerSidePagination(false);
    setIsBulkInspectDialogOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account' />
        ),
        accessor: 'account',
        width: '20%',
        isVisible: !isTreasuryServices,
        filter: 'text',
        sortType: 'caseInsensitiveSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='First Transaction Date' />
        ),
        accessor: 'startDate',
        width: '12%',
        isVisible: !isTreasuryServices,
        filter: 'dateRange',
        sortType: 'customDateTimeSort',
        Cell: ({ cell: { value } }) => value || '---',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Last Transaction Date' />
        ),
        accessor: 'endDate',
        width: '12%',
        isVisible: !isTreasuryServices,
        filter: 'dateRange',
        sortType: 'customDateTimeSort',
        Cell: ({ cell: { value } }) => value || '---',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Start Balance' />
        ),
        accessor: 'startBalance',
        width: '13%',
        isVisible: !isTreasuryServices,
        filter: 'text',
        sortType: 'basic',
        Cell: ({ cell: { value } }) => value || '---',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='End Balance' />
        ),
        accessor: 'endBalance',
        width: '13%',
        isVisible: !isTreasuryServices,
        filter: 'text',
        sortType: 'basic',
        Cell: ({ cell: { value } }) => value || '---',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Section Type' />
        ),
        accessor: 'type',
        width: '25%',
        isVisible: isTreasuryServices,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Total Charges'
            toolTipText='Total eligible charges.'
          />
        ),
        accessor: 'totalCharges',
        width: '25%',
        isVisible: isTreasuryServices,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Status' />
        ),
        accessor: 'status',
        width: '20%',
        isVisible: true,
        filter: 'text',
        sortType: 'caseInsensitiveSort',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={colors.white}
              backgroundColor={
                value === 'Needs Files'
                  ? colors.red
                  : value === 'Needs Validation'
                    ? colors.lightText
                    : colors.purpleText
              }
              fontWeight={400}
              padding={'7px'}
              borderRadius={'5px'}
              style={{ fontSize: '.8rem' }}
            >
              {value}
            </StyleColumnCell>
          );
        },
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        sticky: 'right',
        Cell: ({ row }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {row.original.status === 'Needs Validation' && (
                    <MenuItem
                      text={`Validate${isTreasuryServices ? '' : ' Account'}`}
                      onClick={() => handleValidateBatchAccount(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {(row.original.status === 'Validated' ||
                    row.original.status === 'Needs Validation') &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_STAGING_ACCOUNT_BALANCES,
                      permissions.VIEW_STAGING_BALANCES,
                      permissions.VIEW_STAGING_FEES,
                      permissions.VIEW_STAGING_INVESTMENTS,
                      permissions.VIEW_STAGING_TRANSACTIONS,
                    ]) &&
                    (isHybridAccount ? (
                      <>
                        <MenuItem
                          text='Edit/Inspect/Validate Transactions'
                          onClick={() =>
                            handleStagingTransactionDialogOpen(row.original)
                          }
                          style={{
                            color: `${colors.purpleText}`,
                            fontWeight: '700',
                          }}
                        />
                        <MenuItem
                          text='Edit/Inspect/Validate Investments'
                          onClick={() =>
                            handleStagingInvestmentDialogOpen(row.original)
                          }
                          style={{
                            color: `${colors.purpleText}`,
                            fontWeight: '700',
                          }}
                        />
                      </>
                    ) : (
                      <MenuItem
                        text='Edit/Inspect/Validate'
                        onClick={() =>
                          isInvestment
                            ? handleStagingInvestmentDialogOpen(row.original)
                            : isTreasuryServices
                              ? handleStagingTreasuryServiceDialogOpen(
                                row.original
                              )
                              : handleStagingTransactionDialogOpen(row.original)
                        }
                        style={{
                          color: `${colors.purpleText}`,
                          fontWeight: '700',
                        }}
                      />
                    ))}
                  {checkPermissions(appUser.permList, [
                    permissions.DOWNLOAD_FILES,
                  ]) && (
                      <MenuItem
                        text='Download Source'
                        onClick={() => downloadSource(row.original)}
                        style={{
                          color: `${colors.purpleText}`,
                          fontWeight: '700',
                        }}
                        disabled={row.original.fileIdList ? false : true}
                      />
                    )}
                  {!isTreasuryServices &&
                    checkPermissions(appUser.permList, [
                      permissions.IGNORE_ACCOUNTS_IN_BATCH,
                    ]) && (
                      <>
                        {row.original.status === 'Ignored' ? (
                          <MenuItem
                            text='Restore Account'
                            onClick={() =>
                              handleRestoreBatchAccount(row.original)
                            }
                            style={{
                              color: `${colors.purpleText}`,
                              fontWeight: '700',
                            }}
                          />
                        ) : row.original.status !== 'Validated' ? (
                          <MenuItem
                            text='Ignore Account'
                            onClick={() => checkIgnore(row.original)}
                            style={{
                              color: `${colors.purpleText}`,
                              fontWeight: '700',
                            }}
                          />
                        ) : null}
                      </>
                    )}
                  {!isTreasuryServices &&
                    checkPermissions(appUser.permList, [
                      permissions.DELETE_ACCOUNTS_FROM_BATCHES,
                    ]) && (
                      <>
                        <Menu.Divider />
                        <MenuItem
                          text='Delete'
                          onClick={() =>
                            handleEnableAccountConfirmation(row.original)
                          }
                          style={{
                            color: `${colors.red}`,
                            fontWeight: '700',
                            paddingTop: ' 5px',
                            paddingBottom: ' 5px',
                            marginBottom: '10px',
                          }}
                          disabled={row.original.status === 'Needs Files'}
                        />
                      </>
                    )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },

        isVisible: checkPermissions(appUser.permList, [
          permissions.VALIDATE_ACCOUNTS_IN_BATCH,
          permissions.DELETE_ACCOUNTS_FROM_BATCHES,
          permissions.IGNORE_ACCOUNTS_IN_BATCH,
          permissions.DOWNLOAD_FILES,
        ]),
      },
    ],
    [
      appUser,
      isTreasuryServices,
      isInvestment,
      handleValidateBatchAccount,
      checkIgnore,
      handleEnableAccountConfirmation,
      handleRestoreBatchAccount,
      downloadSource,
      isHybridAccount
    ]
  );

  return (
    <>
      <IgnoreAccountDialog
        isOpen={isIgnoreOpen}
        title={'Ignore Account'}
        handleClose={handleCloseIgnoreConfirmation}
        handleIgnore={handleIgnoreBatchAccount}
        isIgnoring={isIgnoring}
        accountName={accountHasFiles}
      />
      <BatchAccountDeleteDialog
        isOpen={isBatchAccountDeleteDialogEnabled}
        title={'Delete Batch Account'}
        accountName={selectedBatchAccount && selectedBatchAccount.account}
        handleClose={handleCloseAccountConfirmation}
        handleDelete={handleDeleteBatchAccount}
        isDeleting={isDeletingBatchAccount}
      />
      <TableContainer width='100%' padding='0rem 2rem 1rem'>
        <MakeTable
          appUser={appUser}
          data={batchAccounts}
          columns={columns}
          validate={true}
          tableTitle={isTreasuryServices ? 'Analysis Statements' : 'Accounts'}
          handleValidateBatchAccount={handleValidateBatchAccount}
          checkIgnore={checkIgnore}
          handleBulkInspectDialogOpen={handleBulkInspectDialogOpen}
          {...props}
        />
      </TableContainer>
      {isValidateTransactionOpen && (
        <StagingTransactionDialog
          isOpen={isValidateTransactionOpen}
          clientName={clientName}
          bankName={bankName}
          batchAccount={batchAccount}
          accountType={accountType}
          handleCloseValidateTransaction={handleCloseValidateTransaction}
          isServerSidePagination={isServerSidePagination}
          {...props}
        />
      )}
      {isValidateInvestmentOpen && (
        <StagingInvestmentDialog
          appUser={appUser}
          isOpen={isValidateInvestmentOpen}
          clientName={clientName}
          bankName={bankName}
          batchAccount={batchAccount}
          accountType={accountType}
          handleClose={handleCloseValidateInvestment}
          isServerSidePagination={isServerSidePagination}
          {...props}
        />
      )}
      {isValidateTreasuryServiceOpen && (
        <StagingTreasuryServicesDialog
          appUser={appUser}
          batchId={batchId}
          isOpen={isValidateTreasuryServiceOpen}
          clientName={clientName}
          bankName={bankName}
          batchAccount={batchAccount}
          handleClose={handleCloseValidateTreasuryServices}
          {...props}
        />
      )}
      {isBulkInspectDialogOpen && (
        <TransactionBulkInspectDialog
          isOpen={isBulkInspectDialogOpen}
          appUser={appUser}
          clientName={clientName}
          bankName={bankName}
          batchAccountIds={selectedBatchIds}
          handleClose={handleCloseBulkInspectDialog}
          isServerSidePagination={isServerSidePagination}
          {...props}
        />
      )}
    </>
  );
};

export default ValidateBatchTable;
