import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
  ActionIconButton,
  StyleColumnCell,
  TextBox,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import RowSelector from './components/RowSelector';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import {
  checkPermissions,
  localDateFormatter,
  stringToDate,
} from '../../utils/functions';
import permissions from '../../utils/permissions';
import { getMonthDifference, monthYearOnly } from '../../utils/functions';
import MonteCarloResults from '../../containers/Analysis/MonteCarloResults';
import shortid from 'shortid';
import { TableContainer } from './components/table-styled-components';
import MonteCarloDeleteDialogBox from '../dialog/monte-carlo/MonteCarloDeleteDialogBox';

const MonteCarloHistoryTable = ({
  appUser,
  monteCarloHistory,
  isFetching,
  rangeFilter,
  handleRunAnalysisDialogOpen,
  clientId,
  handleEditAndRerun,
  ...props
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  //This is for Delete Dialog
  const [selectedMonteCarlo, setSelectedMonteCarlo] = useState({});
  const [resetInitialFilter] = useState(true);

  useEffect(() => {
    // filterData is all rows created within the last year
    const filterData = monteCarloHistory.filter((row) => {
      // min is date 1 year ago, max is today's date
      const [min, max] = rangeFilter;
      min && min.setHours(0, 0, 0, 0);
      max && max.setHours(0, 0, 0, 0);
      const createdAt = stringToDate(row.createdAt);
      // check row date to determine if it was created in the last year
      const maxFlag = max ? createdAt <= max : true;
      const minFlag = min ? createdAt >= min : true;
      // if both are true, keep the row
      return minFlag && maxFlag;
    });
    // if two or more rows then set selectedRows as an array containing the first two rows.
    // this will allow us to auto-select the first two rows.
    if (filterData.length >= 2) {
      setSelectedRows([filterData[0], filterData[1]]);
      // if one row, automatically select the row.
    } else if (filterData.length === 1) setSelectedRows([filterData[0]]);
    else setSelectedRows([]);
  }, [setSelectedRows, monteCarloHistory, rangeFilter]);

  const deleteSelectedRow = useCallback(
    (id) => {
      const index = selectedRows.findIndex((item) => item.monteCarloId === id);
      selectedRows.splice(index, 1);
    },
    [selectedRows]
  );

  // up to two boxes can be selected at a time.
  const handleSetSelectedRows = useCallback(
    (row) => {
      let selectedIds = selectedRows.map((item) => item.monteCarloId);
      // if box is already selected, de-select the box
      if (selectedIds.includes(row.monteCarloId)) {
        deleteSelectedRow(row.monteCarloId);
        // if two boxes are selected, de-select the oldest checked box,
        // and add the box just clicked as the most recently selected box.
      } else if (selectedRows.length === 2) {
        selectedRows.shift();
        selectedRows.push(row);
      } else {
        // Only one item is currently checked, so check the row just clicked also.
        selectedRows.push(row);
      }
      setSelectedRows([...selectedRows]);
    },
    [selectedRows, deleteSelectedRow]
  );

  const handleDeleteContainer = (row) => {
    deleteSelectedRow(row.monteCarloId);
    setSelectedRows([...selectedRows]);
  };

  const handleCheckAccountValidation = () => {
    props.history.push({
      pathname: `/client-management/${clientId}`,
      state: 2,
    });
  };

  const handleDeleteDialogOpen = (selectedMonteCarlo) => {
    setIsDeleteDialogOpen(true);
    setSelectedMonteCarlo(selectedMonteCarlo);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
    setSelectedMonteCarlo({});
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'createdAt',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        Cell: ({ cell: { value } }) => localDateFormatter(value),
        isVisible: true,
      },
      {
        Header: () => 'Date Range',
        id: 'Date Range',
        width: '30%',
        isVisible: true,
        accessor: (row) => {
          const monthDifference = getMonthDifference(
            row.startDateRange,
            row.endDateRange
          );
          return `${monthDifference} Months (${monthYearOnly(
            row.startDateRange
          )} - ${monthYearOnly(row.endDateRange)})`;
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Analysis Group' />
        ),
        accessor: 'groupName',
        filter: 'multipleValues',
        width: '15%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => (
          <TextBox
            key={shortid.generate()}
            background={colors.purpleText}
            margin='2px 3px'
            padding='10px'
          >
            {value}
          </TextBox>
        ),
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Name' />
        ),
        accessor: 'monteCarloName',
        filter: 'multipleValues',
        width: '15%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Data Has Changed?' />
        ),
        accessor: 'dataHasChanged',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'yesOrNo',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell color={value === 'NO' ? colors.green : colors.red}>
              {value}
            </StyleColumnCell>
          );
        },
        isVisible: true,
      },
      {
        Header: () => 'Viewing',
        id: 'viewing',
        width: '15%',
        Cell: ({ row }) => {
          let selectedIds = selectedRows.map((item) => item.monteCarloId);

          return (
            <RowSelector
              onClick={() => {
                handleSetSelectedRows(row.original);
              }}
              isViewingColumn={true}
              {...row.getToggleRowSelectedProps()}
              isSelected={selectedIds.includes(row.original.monteCarloId)}
            />
          );
        },
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.RUN_MONTE_CARLO_MODEL,
                ]) && (
                    <MenuItem
                      text='Edit and Rerun'
                      onClick={() => handleEditAndRerun(row.original)}
                      style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.EDIT_ACCOUNT_GROUPS,
                ]) && (
                    <MenuItem
                      text='Edit Account Group'
                      href={`/client-management/${clientId}/account-group/${row.original.groupId}`}
                      target='_blank'
                      style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.DELETE_MONTE_CARLO_MODEL,
                ]) && (
                    <>
                      <Menu.Divider />
                      <MenuItem
                        text='Delete'
                        onClick={() => {
                          handleDeleteDialogOpen(row.original);
                        }}
                        style={{
                          color: `${colors.red}`,
                          fontWeight: '700',
                          paddingTop: ' 5px',
                          paddingBottom: ' 5px',
                          marginBottom: '10px',
                        }}
                      />
                    </>
                  )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.RUN_MONTE_CARLO_MODEL,
          permissions.VIEW_MONTE_CARLO_ANALYSIS,
          permissions.VIEW_ANALYSIS,
          permissions.EDIT_ACCOUNT_GROUPS,
          permissions.DELETE_MONTE_CARLO_MODEL,
        ]),
      },
    ],
    [appUser, selectedRows, clientId, handleSetSelectedRows, handleEditAndRerun]
  );
  return (
    <>
      <TableContainer padding='0rem'>
        <MakeTable
          data={monteCarloHistory}
          appUser={appUser}
          columns={columns}
          tableTitle='Monté Carlo History'
          isFetching={isFetching}
          isViewableColumnTable={true}
          selectedRows={selectedRows}
          rangeFilter={rangeFilter}
          handleOpenRunAnalysisDialog={handleRunAnalysisDialogOpen}
          resetInitialFilter={resetInitialFilter}
          rangeFilterName='Created Date'
          handleCheckAccountValidation={handleCheckAccountValidation}
          initialState={{
            sortBy: [{ id: 'createdAt', desc: true }]
          }}
          {...props}
        />
      </TableContainer>
      {selectedRows.map((row) => (
        <MonteCarloResults
          selectedRow={row}
          key={shortid.generate()}
          handleDeleteContainer={handleDeleteContainer}
        />
      ))}
      <MonteCarloDeleteDialogBox
        clientId={clientId}
        isOpen={isDeleteDialogOpen}
        title={'Delete Monté Carlo'}
        handleClose={handleDeleteDialogClose}
        selectedMonteCarlo={selectedMonteCarlo}
      />
    </>
  );
};

export default MonteCarloHistoryTable;
