import React from 'react';
import {AlignCenter, FlexColumn} from '../styled-components';
import {Formik, Form} from 'formik';
import ModalTextField from '../formik-text-fields/ModalTextField';
import {checkPermissions, dataAvailabilityDateList} from '../../utils/functions';
import permissions from '../../utils/permissions';
import find from 'lodash/find';
import {statusList, allowedDataGatheringFrequenciesList} from '../../utils/constantsList';
import * as Yup from 'yup';
import FormActions from './FormActions';
import SelectFieldFormik from '../select-fields/SelectFieldFormik';

const bankSchema = Yup.object().shape({
    bankName: Yup.string().required('Required!'),
    assetSize: Yup.number()
        .positive('Field must be a positive number.')
        .required('Required!'),
    defaultDataGatheringFrequency: Yup.number().required('Required!'),
    dataAvailabilityDate: Yup.number().required('Required!')
});

const onAddTransform = (values) => {
    let copiedValues = Object.assign({}, values);

    //Change field names for adding bank
    const addBankName = copiedValues.bankName;
    delete copiedValues.bankName;
    copiedValues = {...copiedValues, addBankName};

    const addAssetSize = copiedValues.assetSize;
    delete copiedValues.assetSize;
    copiedValues = {...copiedValues, addAssetSize};

    return copiedValues;
};


const BankForm = ({
                      title,
                      bankName,
                      assetSize,
                      status,
                      defaultDataGatheringFrequency,
                      dataAvailabilityDate,
                      allowedDataAvailabilityDates,
                      fdicCode,
                      handleCreateBank,
                      closeDialog,
                      handleEditBank,
                      isTryingToSubmit,
                      appUser,
                      bankId,
                  }) => {
    const allowedDates = dataAvailabilityDateList(allowedDataAvailabilityDates);

    const setDefaultDataGatheringFrequency = (allowedFrequencies, values) => {
        return (allowedFrequencies.map(item => item.value)).includes(values.defaultDataGatheringFrequency)
            ? find(allowedFrequencies, ['value', values.defaultDataGatheringFrequency]).label
            : values.defaultDataGatheringFrequency === undefined ? 'Not Set'
                : String(values.defaultDataGatheringFrequency).concat(' months')
    };

    const setDataAvailabilityDate = (allowedFrequencies, values) => {
        return (allowedDates.map(item => item.value)).includes(values.dataAvailabilityDate)
            ? find(allowedDates, ['value', values.dataAvailabilityDate]).label
            : values.dataAvailabilityDate === undefined ? 'Not Set'
                : String(values.dataAvailabilityDate)
    };
    return (
        <>
            <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
                <Formik
                    initialValues={{
                        bankName,
                        assetSize,
                        status,
                        defaultDataGatheringFrequency,
                        dataAvailabilityDate,
                        fdicCode
                    }}
                    validateOnBlur={false}
                    validationSchema={bankSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        if (title === 'Add New Bank')
                            handleCreateBank(onAddTransform(values));
                        else handleEditBank(values, bankId);
                    }}
                >
                    {({values, setFieldValue}) => (

                        <Form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            {/* custom field using formik hooks */}
                            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                                <FlexColumn>
                                    <ModalTextField
                                        type='text'
                                        name='bankName'
                                        placeholder='Bank Name'
                                        label='Bank Name'
                                        value={values.bankName || ''}
                                        autoComplete='off'
                                    />
                                </FlexColumn>
                                <FlexColumn>
                                    <ModalTextField
                                        type='number'
                                        name='assetSize'
                                        placeholder='Asset Size'
                                        label='Asset Size (millions)'
                                        value={values.assetSize || ''}
                                        autoComplete='off'
                                    />
                                </FlexColumn>
                            </AlignCenter>
                            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                                <FlexColumn>
                                    {checkPermissions(appUser.permList, [
                                        permissions.DEACTIVATE_BANKS,
                                    ]) && (
                                        <SelectFieldFormik
                                            setFieldValue={setFieldValue}
                                            name='status'
                                            value={
                                                values.status >= 0
                                                    ? find(statusList, ['value', values.status]).label
                                                    : 'Active'
                                            }
                                            items={statusList}
                                            labelName='Active Status'
                                        />
                                    )}
                                </FlexColumn>
                                <FlexColumn>
                                    <SelectFieldFormik
                                        setFieldValue={setFieldValue}
                                        name='defaultDataGatheringFrequency'
                                        value={setDefaultDataGatheringFrequency(allowedDataGatheringFrequenciesList, values)}
                                        items={allowedDataGatheringFrequenciesList}
                                        labelName='Data Gathering Frequency'
                                    />
                                </FlexColumn>
                            </AlignCenter>
                            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                                <SelectFieldFormik
                                    setFieldValue={setFieldValue}
                                    name='dataAvailabilityDate'
                                    value={setDataAvailabilityDate(allowedDates, values)}
                                    items={allowedDates}
                                    labelName='Data Availability Date'
                                />
                                <FlexColumn>
                                    <ModalTextField
                                        type='text'
                                        name='fdicCode'
                                        placeholder='FDIC #'
                                        label='FDIC #'
                                        value={values.fdicCode || ''}
                                        autoComplete='off'
                                    />
                                </FlexColumn>
                            </AlignCenter>
                            <FormActions
                                isSubmitting={isTryingToSubmit}
                                onClose={closeDialog}
                            />
                        </Form>
                    )}
                </Formik>
            </AlignCenter>
        </>
    );
};

export default BankForm;
