import { Icon } from '@blueprintjs/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import shortid from 'shortid';
import colors from '../../utils/colors';
import {
  checkPermissions,
  getMonthDifference,
  getOneYearRange,
  localDateFormatter,
  monthYearOnly,
  stringToDate,
} from '../../utils/functions';
import iconValue from '../../utils/icons';
import permissions from '../../utils/permissions';
import {
  ActionIconButton,
  StyleColumnCell,
  TextBox,
  AlignCenter,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import ShortTermModelResults from '../../containers/Analysis/ShortTermModelResults';
import { TableContainer } from './components/table-styled-components';

const StepTwoAnalysisTable = ({
  stepTwoAnalysis,
  isFetching,
  rangeFilter,
  getOneYearRangeFilter = getOneYearRange(),
  appUser,
  handleDeleteDialogOpen,
  clientId,
  ...props
}) => {
  const [resetInitialFilter] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    // filterData is all rows created within the last year
    const filterData = stepTwoAnalysis.filter((row) => {
      // min is date 1 year ago, max is today's date
      const [min, max] = rangeFilter;
      min && min.setHours(0, 0, 0, 0);
      max && max.setHours(0, 0, 0, 0);
      const createdAt = stringToDate(row.createdAt);
      // check row date to determine if it was created in the last year
      const maxFlag = max ? createdAt <= max : true;
      const minFlag = min ? createdAt >= min : true;
      // if both are true, keep the row
      return minFlag && maxFlag;
    });
    // if two or more rows then set selectedRows as an array containing the first two rows.
    // this will allow us to auto-select the first two rows.
    if (filterData.length >= 2) {
      setSelectedRows([filterData[0], filterData[1]]);
      // if one row, automatically select the row.
    } else if (filterData.length === 1) setSelectedRows([filterData[0]]);
    else setSelectedRows([]);
  }, [setSelectedRows, stepTwoAnalysis, rangeFilter]);

  const deleteSelectedRow = useCallback(
    (id) => {
      const index = selectedRows.findIndex(
        (item) => item.shortTermModelId === id
      );
      selectedRows.splice(index, 1);
    },
    [selectedRows]
  );

  // up to two boxes can be selected at a time.
  const handleSetSelectedRows = useCallback(
    (row) => {
      let selectedIds = selectedRows.map((item) => item.shortTermModelId);
      // if box is already selected, de-select the box
      if (selectedIds.includes(row.shortTermModelId)) {
        deleteSelectedRow(row.shortTermModelId);
        // if two boxes are selected, de-select the oldest checked box,
        // and add the box just clicked as the most recently selected box.
      } else if (selectedRows.length === 2) {
        selectedRows.shift();
        selectedRows.push(row);
      } else {
        // Only one item is currently checked, so check the row just clicked also.
        selectedRows.push(row);
      }
      setSelectedRows([...selectedRows]);
    },
    [selectedRows, deleteSelectedRow]
  );

  const handleDeleteContainer = (row) => {
    deleteSelectedRow(row.shortTermModelId);
    setSelectedRows([...selectedRows]);
  };

  const handleCheckAccountValidation = () => {
    props.history.push({
      pathname: `/client-management/${clientId}`,
      state: 2,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'createdAt',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        Cell: ({ cell: { value } }) => localDateFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date Range' />
        ),
        id: 'dateRange',
        accessor: (row) => {
          const monthDifference = getMonthDifference(
            row.startDateRange,
            row.endDateRange
          );
          return `${monthDifference} Months (${monthYearOnly(
            row.startDateRange
          )} to ${monthYearOnly(row.endDateRange)})`;
        },
        width: '30%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account Group' />
        ),
        accessor: 'groupName',
        width: '15%',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        Cell: ({ cell: { value } }) => (
          <TextBox
            key={shortid.generate()}
            background={colors.purpleText}
            margin='2px 3px'
            padding='10px'
          >
            {value}
          </TextBox>
        ),
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Name' />
        ),
        accessor: 'shortTermModelName',
        width: '15%',
        filter: 'multipleValues',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Step 2 Ready?' />
        ),
        accessor: 'stepStatus',
        width: '15%',
        sortType: 'caseInsensitiveSort',
        filter: 'yesOrNo',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'YES' ? colors.primary : colors.lightText}
            >
              {value}
            </StyleColumnCell>
          );
        },
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Data Has Changed?' />
        ),
        accessor: 'dataHasChanged',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'yesOrNo',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell color={value === 'NO' ? colors.green : colors.red}>
              {value}
            </StyleColumnCell>
          );
        },
        isVisible: true,
      },
      {
        Header: () => 'Viewing',
        id: 'viewing',
        width: '15%',
        Cell: ({ row }) => {
          let selectedIds = selectedRows.map((item) => item.shortTermModelId);
          return (
            <RowSelector
              onClick={() => {
                handleSetSelectedRows(row.original);
              }}
              isViewingColumn={true}
              {...row.getToggleRowSelectedProps()}
              isSelected={selectedIds.includes(row.original.shortTermModelId)}
            />
          );
        },
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <AlignCenter justifyContent='start'>
            {checkPermissions(appUser.permList, [
              permissions.DELETE_SHORT_TERM_MODEL,
            ]) && (
                <ActionIconButton
                  margin='0 0 0 8px'
                  style={{ padding: '5px' }}
                  onClick={() => handleDeleteDialogOpen(row.original, 2)}
                >
                  <Icon
                    icon={iconValue.trash}
                    iconSize={20}
                    color={colors.primary}
                  />
                </ActionIconButton>
              )}
          </AlignCenter>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.DELETE_SHORT_TERM_MODEL,
          permissions.RUN_SHORT_TERM_MODEL,
          permissions.VIEW_SHORT_TERM_MODEL,
        ]),
      },
    ],
    [appUser, handleSetSelectedRows, selectedRows, handleDeleteDialogOpen]
  );
  return (
    <>
      <TableContainer padding='2rem 0 0'>
        <MakeTable
          appUser={appUser}
          data={stepTwoAnalysis}
          columns={columns}
          isFetching={isFetching}
          tableTitle='Step 2 Analysis'
          rangeFilter={getOneYearRangeFilter}
          rangeFilterName='Created At'
          resetInitialFilter={resetInitialFilter}
          handleCheckAccountValidation={handleCheckAccountValidation}
          isViewableColumnTable={true}
          selectedRows={selectedRows}
          initialState={{
            sortBy: [{ id: 'createdAt', desc: true }]
          }}
          {...props}
        />
      </TableContainer>
      {selectedRows.map((row) => {
        return (
          <ShortTermModelResults
            appUser={appUser}
            selectedRow={row}
            key={shortid.generate()}
            handleDeleteContainer={handleDeleteContainer}
            clientId={clientId}
          />
        );
      })}
    </>
  );
};

export default StepTwoAnalysisTable;
