import React from 'react';
import {
  AlignCenter,
  RegularButton,
  ErrorMessageDiv,
  FlexColumn,
} from '../../styled-components';
import { Formik, Form } from 'formik';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import colors from '../../../utils/colors';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import { checkPermissions, onKeyDown } from '../../../utils/functions';
import permissions from '../../../utils/permissions';
import find from 'lodash/find';
import { statusList } from '../../../utils/constantsList';
import * as Yup from 'yup';
import LoadSpinner from '../../../components/LoadSpinner';
import FormActions from '../FormActions';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import FormikMultiSelectField from '../../multi-select-fields/FormikMultiSelectField';

const CLIENT_ROLE_VALUE = 3;

const userSchema = Yup.object().shape({
  firstName: Yup.string().required('Required!'),
  lastName: Yup.string().required('Required!'),
  email: Yup.string().email('Must be valid email.').required('Required!'),
  role: Yup.string().required('Required!'),
});

const onEditTransform = (values) => {
  let copiedValues = Object.assign({}, values);

  const clientAssociation = copiedValues.clientAssociation.map(
    (client) => client.value
  );
  delete copiedValues.clientAssociation;
  copiedValues = { ...copiedValues, clientAssociation };

  return copiedValues;
};

const UserForm = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  status,
  role,
  roles,
  resetMfaMessage,
  clientList,
  clientAssociation,
  showGreenMessage,
  closeDialog,
  handleEditUser,
  resetUserMfa,
  appUser,
  isResetting,
  resendConfirmationCode,
  isResendingCode,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
        <Formik
          initialValues={{
            firstName,
            lastName,
            email,
            phoneNumber,
            role,
            status,
            clientAssociation,
          }}
          validateOnBlur={false}
          validationSchema={userSchema}
          enableReinitialize={true}
          // NEED BRACKETS FOR isSubmitting TO WORK WITH DISABLING BUTTON AND SPINNER
          onSubmit={(values, actions) => {
            handleEditUser(onEditTransform(values), actions);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form
              onKeyDown={onKeyDown}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {/* custom field using formik hooks */}
              <AlignCenter justifyContent='space-evenly'>
                <FlexColumn>
                  <ModalTextField
                    type='text'
                    name='email'
                    placeholder='Email'
                    label='Email Address'
                    value={values.email || ''}
                    autoComplete='off'
                  />
                </FlexColumn>
                <FlexColumn>
                  <SelectFieldFormik
                    setFieldValue={(field, value, shouldValidate) => {
                      setFieldValue(field, value, shouldValidate);
                      setFieldValue('clientAssociation', []);
                    }}
                    name='role'
                    value={
                      values.role !== ''
                        ? find(roles, ['value', values.role]).label
                        : ''
                    }
                    items={roles}
                    labelName='User Role'
                  />
                </FlexColumn>
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='25px 0'>
                <FlexColumn>
                  <ModalTextField
                    type='text'
                    name='firstName'
                    placeholder='First Name'
                    label='First Name'
                    value={values.firstName || ''}
                    autoComplete='off'
                  />
                </FlexColumn>
                <FlexColumn>
                  <ModalTextField
                    type='text'
                    name='lastName'
                    placeholder='Last Name'
                    label='Last Name'
                    value={values.lastName || ''}
                    autoComplete='off'
                  />
                </FlexColumn>
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly'>
                {checkPermissions(appUser.permList, [
                  permissions.DEACTIVATE_USERS,
                ]) && (
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    name='status'
                    value={
                      values.status >= 0
                        ? find(statusList, ['value', values.status]).label
                        : 'Active'
                    }
                    items={statusList}
                    labelName='Active Status'
                  />
                )}
                <FlexColumn>
                  <ModalTextField
                    type='text'
                    name='phoneNumber'
                    placeholder=''
                    label='Phone Number'
                    value={values.phoneNumber || ''}
                    autoComplete='off'
                    isDisabled={values.phoneNumber === ''}
                  />
                </FlexColumn>
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly'>
                {checkPermissions(appUser.permList, [
                  permissions.RESEND_INVITE,
                ]) && (
                  <FlexColumn>
                    <RegularButton
                      margin='30px 0'
                      width='271px'
                      display='flex'
                      type='button'
                      onClick={resendConfirmationCode}
                      disabled={isResendingCode}
                    >
                      Resend Code
                      {isResendingCode && (
                        <span style={{ marginLeft: '10px' }}>
                          <LoadSpinner size={20} />
                        </span>
                      )}
                    </RegularButton>
                  </FlexColumn>
                )}
                {checkPermissions(appUser.permList, [permissions.RESET_MFA]) ? (
                  <FlexColumn>
                    <RegularButton
                      margin='30px 0'
                      width='271px'
                      display='flex'
                      type='button'
                      onClick={resetUserMfa}
                      disabled={isResetting}
                    >
                      Reset MFA
                      {isResetting && (
                        <span style={{ marginLeft: '10px' }}>
                          <LoadSpinner size={20} />
                        </span>
                      )}
                    </RegularButton>
                    {resetMfaMessage && (
                      <>
                        {!showGreenMessage && (
                          <AlignCenter justifyContent='space-between'>
                            <ErrorMessageDiv fontSize='1rem'>
                              MFA reset request failed.
                            </ErrorMessageDiv>
                            <Icon
                              icon={iconValue.warning}
                              iconSize={16}
                              color={colors.red}
                            />
                          </AlignCenter>
                        )}
                        {showGreenMessage && (
                          <AlignCenter justifyContent='space-between'>
                            <ErrorMessageDiv
                              fontSize='1rem'
                              color={colors.green}
                            >
                              MFA reset request successful.
                            </ErrorMessageDiv>
                            <Icon
                              icon={iconValue.check}
                              iconSize={16}
                              color={colors.green}
                            />
                          </AlignCenter>
                        )}
                      </>
                    )}
                  </FlexColumn>
                ) : (
                  <div style={{ minWidth: '271px' }}></div>
                )}
              </AlignCenter>

              <AlignCenter justifyContent='flex-start' padding='25px 50px 0'>
                <FlexColumn>
                  <FormikMultiSelectField
                    setSelectedItems={setFieldValue}
                    items={clientList}
                    selectedItems={values.clientAssociation}
                    label='Assign Clients'
                    fill={true}
                    name='clientAssociation'
                    disabled={!values.role}
                  />
                </FlexColumn>
              </AlignCenter>

              <FormActions isSubmitting={isSubmitting} onClose={closeDialog} />
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default UserForm;
