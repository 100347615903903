import React, { useState, useEffect, useRef } from 'react';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
  useFilters,
} from 'react-table';
import styled from 'styled-components';
import GlobalFilter from './GlobalFilter';
import Pagination from './Pagination';
import NonIdealStateComp from '../../Non-Ideal-State';
import LoadSpinner from '../../LoadSpinner';
import iconValue from '../../../utils/icons';
import { dateToFilterText, getXDaysFromNow } from '../../../utils/functions';
import FilterSummary from '../../../components/FilterSummary';
import { sortTypes, filterTypes } from '../../../utils/constantsList';
import { useSticky } from 'react-table-sticky';
import { TableWrapper } from './table-styled-components';

const usePrevious = (value) => {
  const ref = useRef();
  ref.current = value;
  return ref.current;
};

const MakeTable = ({
  appUser,
  columns,
  data,
  isFetching,
  tableTitle,
  customPageOptions,
  openBulkEditDialog,
  handleDeleteDialogOpen,
  handleBulkDownload,
  startBulkReports,
  isBulkFetching,
  validate,
  handleValidateBatchAccount,
  checkIgnore,
  handleValidateTransaction,
  handleInvalidateTransaction,
  removeMultipleFiles,
  toggleAllowOverwrite,
  investments,
  handleBulkDownloadInvestment,
  rangeFilter,
  rangeFilterName,
  openAddKeywords,
  handleOpenAddDialog,
  groupId,
  openAddNoteCategories,
  handleAddDialogOpen,
  boxShadow,
  isDashboard,
  handleResolveDialogOpen,
  handleRestoreDialogOpen,
  resetInitialFilter,
  handleChangeShow,
  isShow,
  hiddenColumns,
  userClients,
  showUsersClients,
  isCurrentInvestments,
  maturityLimit,
  handleValidateInvestment,
  handleInvalidateInvestment,
  isStagingInvestment,
  isViewableColumnTable,
  selectedRows,
  fromMaturingInvestmentsWidget,
  handleOpenRunAnalysisDialog,
  isModelingForecastWidget,
  handleCheckAccountValidation,
  handleSetStepToRun,
  isTreasuryServices,
  dynamicHeaders,
  handleBulkInspectDialogOpen,
  handleValidateStagingTable,
  handleInvalidateStagingTable,
  handleBulkEditStagingTable,
  handleGenerateReportDialogOpen,
  handleStartIngestionDialogOpen,
  clientReportStatus,
  handleOpenTemplate,
  handleOpenExtractionWarningDialog,
  setResetInitialFilter,
  isServerSidePagination,
  // callback from account profile page for server pagination
  updatePaginate,
  resultCount,
  pageCount: controlledPageCount,
  ...props
}) => {
  const [filterSummary, setFilterSummary] = useState([]);
  const [getFilters, setGetFilters] = useState([]);
  const [sortOrder, setSortOrder] = useState({});
  const sort = useRef();
  const prevSort = usePrevious(sort.current);
  // state for clicking pagination arrows or setting number of rows
  const [moreNextPrev, setMoreNextPrev] = useState(false);
  const [runFilterPaginate, setRunFilterPaginate] = useState();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //page will have all visible rows
    page,
    //all rows
    rows,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setPageSize,
    visibleColumns,
    pageCount,
    //This is for globalfilter
    state,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    //Sets the hidden columns
    setHiddenColumns,
    selectedFlatRows,
    //this is for pagination
    state: { pageIndex, pageSize },
  } = useTable(
    // useTable is hook which give us headless props
    // columns, data, and initialState needs to be pass as props
    // We use three other hooks usePagination, useGlobalFilters, and useSortBy
    // Order of those matters because we cannot put usePagination before any of other two
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: customPageOptions ? customPageOptions[0] : 10,
        sortBy: props.initialState?.sortBy || []
      },
      filterTypes,
      sortTypes,
      // for server side pagination
      autoResetPage: isServerSidePagination ? false : true,
      manualPagination: isServerSidePagination ? true : false,
      autoResetSortBy: isServerSidePagination ? false : true,
      // from server call
      pageCount: controlledPageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useRowSelect,
    usePagination,
    useSticky
  );

  //create a array of original of selected row
  const selectedRowsOriginal = selectedFlatRows.map((row) => row.original);

  sort.current = state.sortBy;

  const bool = sort.current === prevSort ? true : false;
  if (bool === false) {
    setSortOrder(sort.current);
    if (tableTitle === 'All Investments' || tableTitle === 'Client Investments')
      setResetInitialFilter(true);
  }

  //Sets hidden columns
  useEffect(() => {
    setHiddenColumns(
      columns
        .filter((column) => !column.isVisible)
        .map((column) => column.id || column.accessor)
    );
  }, [setHiddenColumns, columns]);

  //Sets the default filter summary
  useEffect(() => {
    if (resetInitialFilter) {
      const filters = [];
      rangeFilter &&
        !showUsersClients &&
        filters.push(dateToFilterText(rangeFilter, rangeFilterName));
      isDashboard &&
        filters.push({
          name: 'Show All Notes',
          value: 'No, only show me notes relevant to me',
        });
      if (isCurrentInvestments && showUsersClients) {
        !fromMaturingInvestmentsWidget &&
          filters.push({
            name: 'Show All Investments',
            value: 'No, only show my clients investments',
          });
        maturityLimit &&
          maturityLimit.globalSettingValue &&
          filters.push(
            dateToFilterText(
              getXDaysFromNow(Number(maturityLimit.globalSettingValue)),
              'Maturity date'
            )
          );
      }
      rangeFilter && setFilterSummary(filters);
    }
  }, [
    rangeFilter,
    isDashboard,
    rangeFilterName,
    resetInitialFilter,
    isCurrentInvestments,
    showUsersClients,
    maturityLimit,
    fromMaturingInvestmentsWidget,
  ]);

  // if user changes rows per page or page index
  const runUpdatePaginate = () => {
    setMoreNextPrev(true);
    setRunFilterPaginate(false);
  };

  const TableData = styled.td`
    &.highlightedRow {
      background: #e6e6fa !important;
    }
  `;

  return (
    <>
      {filterSummary.length && !isFetching ? (
        <FilterSummary
          filters={filterSummary}
          preFilteredLength={data.length}
          filteredLength={rows.length}
        />
      ) : null}
      {/* It renders table with given table props from useTable */}
      <div>
        <GlobalFilter
          appUser={appUser}
          groupId={groupId}
          resetInitialFilter={resetInitialFilter}
          setFilter={setFilter}
          setAllFilters={setAllFilters}
          tableTitle={tableTitle}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          selectedRows={selectedRowsOriginal}
          openBulkEditDialog={openBulkEditDialog}
          handleDeleteDialogOpen={handleDeleteDialogOpen}
          handleBulkDownload={handleBulkDownload}
          startBulkReports={startBulkReports}
          isBulkFetching={isBulkFetching}
          validate={validate}
          handleValidateBatchAccount={handleValidateBatchAccount}
          checkIgnore={checkIgnore}
          handleValidateTransaction={handleValidateTransaction}
          handleInvalidateTransaction={handleInvalidateTransaction}
          removeMultipleFiles={removeMultipleFiles}
          toggleAllowOverwrite={toggleAllowOverwrite}
          investments={investments}
          initialData={data}
          handleBulkDownloadInvestment={handleBulkDownloadInvestment}
          setFilterSummary={setFilterSummary}
          rangeFilter={rangeFilter}
          openAddKeywords={openAddKeywords}
          handleOpenAddDialog={handleOpenAddDialog}
          handleAddDialogOpen={handleAddDialogOpen}
          openAddNoteCategories={openAddNoteCategories}
          isDashboard={isDashboard}
          handleResolveDialogOpen={handleResolveDialogOpen}
          handleRestoreDialogOpen={handleRestoreDialogOpen}
          handleChangeShow={handleChangeShow}
          isShow={isShow}
          hiddenColumns={hiddenColumns}
          userClients={userClients}
          showUsersClients={showUsersClients}
          isCurrentInvestments={isCurrentInvestments}
          maturityLimit={maturityLimit}
          handleBulkInspectDialogOpen={handleBulkInspectDialogOpen}
          handleValidateInvestment={handleValidateInvestment}
          handleInvalidateInvestment={handleInvalidateInvestment}
          isStagingInvestment={isStagingInvestment}
          fromMaturingInvestmentsWidget={fromMaturingInvestmentsWidget}
          handleOpenRunAnalysisDialog={handleOpenRunAnalysisDialog}
          isModelingForecastWidget={isModelingForecastWidget}
          handleCheckAccountValidation={handleCheckAccountValidation}
          handleSetStepToRun={handleSetStepToRun}
          isTreasuryServices={isTreasuryServices}
          dynamicHeaders={dynamicHeaders}
          handleValidateStagingTable={handleValidateStagingTable}
          handleInvalidateStagingTable={handleInvalidateStagingTable}
          handleBulkEditStagingTable={handleBulkEditStagingTable}
          handleGenerateReportDialogOpen={handleGenerateReportDialogOpen}
          clientReportStatus={clientReportStatus}
          handleOpenTemplate={handleOpenTemplate}
          handleOpenExtractionWarningDialog={handleOpenExtractionWarningDialog}
          setResetInitialFilter={setResetInitialFilter}
          isServerSidePagination={isServerSidePagination}
          handleStartIngestionDialogOpen={handleStartIngestionDialogOpen}
          // props below are for server side pagination. To send the filter data to backend
          updatePaginate={updatePaginate}
          pageSize={pageSize}
          pageIndex={pageIndex}
          getFilters={getFilters}
          setGetFilters={setGetFilters}
          sortOrder={sortOrder}
          prevSort={prevSort}
          moreNextPrev={moreNextPrev}
          setMoreNextPrev={setMoreNextPrev}
          runFilterPaginate={runFilterPaginate}
          setRunFilterPaginate={setRunFilterPaginate}
          {...props}
        />
      </div>
      <TableWrapper boxShadow={boxShadow}>
        <table {...getTableProps()}>
          <thead className='sticky'>
            {/* Loops through header element and renders with given width*/}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getFooterGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    //Table headers props
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    width={column.width}
                  >
                    {/* Renders column header here */}
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {isFetching && (
            <tbody>
              <tr>
                <td colSpan={visibleColumns.length}>
                  <LoadSpinner size={100} />
                </td>
              </tr>
            </tbody>
          )}
          {/* Get renders when table has no data */}
          {!isFetching && rows.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={visibleColumns.length}>
                  <NonIdealStateComp
                    icon={iconValue.warning}
                    title={'No Results.'}
                    description={'There are no results to be displayed.'}
                  />
                </td>
              </tr>
            </tbody>
          )}
          {/*page has all visible rows of the page so it is doing for each on all visible rows.
          Each row has cells, so in line 119 its mapping to get cell.
          And cell.render('Cell') renders the cell value
        */}

          <tbody {...getTableBodyProps()}>
            {!isFetching &&
              rows.length > 0 &&
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      let isSelected = false;
                      if (isViewableColumnTable) {
                        isSelected = selectedRows.find((item) =>
                          tableTitle === 'Monté Carlo History'
                            ? item.monteCarloId === row.original.monteCarloId
                            : tableTitle === 'cashVest Score History'
                              ? item.cashVestId === row.original.cashVestId
                              : tableTitle === 'Step 2 Analysis'
                                ? item.shortTermModelId ===
                                row.original.shortTermModelId
                                : null
                        );
                      }
                      return (
                        <TableData
                          className={
                            isViewableColumnTable && isSelected
                              ? 'highlightedRow'
                              : ''
                          }
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </TableData>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
          <thead>
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{ textAlign: 'left', padding: '1rem' }}
                height='50'
                className='pagination'
              >
                {/* This is for pagination*/}
                <Pagination
                  customPageOptions={customPageOptions}
                  canPreviousPage={canPreviousPage}
                  pageCount={pageCount}
                  canNextPage={canNextPage}
                  setPageSize={setPageSize}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  visibleRows={page.length}
                  isServerSidePagination={isServerSidePagination}
                  runUpdatePaginate={runUpdatePaginate}
                  // if server side, use result count from server call
                  rows={isServerSidePagination ? resultCount : rows.length}
                />
              </th>
            </tr>
          </thead>
        </table>
      </TableWrapper>
    </>
  );
};

export default MakeTable;
