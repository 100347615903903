import React, { useMemo, useState } from 'react';
import { Icon, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import colors from '../../utils/colors';
import {
  checkPermissions,
  getMonthDifference,
  getOneYearRange,
  localDateFormatter,
  monthYearOnly,
} from '../../utils/functions';
import iconValue from '../../utils/icons';
import permissions from '../../utils/permissions';
import {
  ActionIconButton,
  StyleColumnCell,
  TextBox,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import shortid from 'shortid';
import { TableContainer } from './components/table-styled-components';

const StepOneAnalysisTable = ({
  stepOneAnalysis,
  isFetching,
  appUser,
  handleDownload,
  handleOpenRunAnalysisDialog,
  clientId,
  isDownloading,
  handleDeleteDialogOpen,
  handleEditAndRerun,
  handleSetStepToRun,
  rangeFilter = getOneYearRange(),
  ...props
}) => {
  const [resetInitialFilter] = useState(true);

  const handleCheckAccountValidation = () => {
    props.history.push({
      pathname: `/client-management/${clientId}`,
      state: 2,
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'createdAt',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        Cell: ({ cell: { value } }) => localDateFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date Range' />
        ),
        id: 'dateRange',
        accessor: (row) => {
          // round monthDifference to the nearest whole month
          const monthDifference = Math.round(
            getMonthDifference(row.startDateRange, row.endDateRange)
          );
          return `${monthDifference} Months (${monthYearOnly(
            row.startDateRange
          )} - ${monthYearOnly(row.endDateRange)})`;
        },
        width: '30%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account Group' />
        ),
        accessor: 'groupName',
        width: '15%',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        Cell: ({ cell: { value } }) => (
          <TextBox
            key={shortid.generate()}
            background={colors.purpleText}
            margin='2px 3px'
            padding='10px'
          >
            {value}
          </TextBox>
        ),
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Name' />
        ),
        accessor: 'shortTermModelName',
        width: '15%',
        filter: 'multipleValues',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Step 1 Ready?' />
        ),
        accessor: 'stepStatus',
        width: '15%',
        sortType: 'caseInsensitiveSort',
        filter: 'yesOrNo',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'YES' ? colors.primary : colors.lightText}
            >
              {value}
            </StyleColumnCell>
          );
        },
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Data Has Changed?' />
        ),
        accessor: 'dataHasChanged',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'yesOrNo',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell color={value === 'NO' ? colors.green : colors.red}>
              {value}
            </StyleColumnCell>
          );
        },
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.RUN_SHORT_TERM_MODEL,
                ]) && (
                    <MenuItem
                      text='Run Step 2'
                      onClick={() => {
                        handleSetStepToRun(2);
                        handleEditAndRerun(row.original);
                      }}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.DOWNLOAD_FILES,
                ]) && (
                    <MenuItem
                      text='Download PDF'
                      onClick={() =>
                        handleDownload(clientId, row.original.fileUrl)
                      }
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.RUN_SHORT_TERM_MODEL,
                ]) && (
                    <MenuItem
                      text='Edit & Rerun'
                      onClick={() => {
                        handleSetStepToRun(1);
                        handleEditAndRerun(row.original);
                      }}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.DELETE_SHORT_TERM_MODEL,
                ]) && (
                    <>
                      <Menu.Divider />
                      <MenuItem
                        text='Delete'
                        onClick={() => handleDeleteDialogOpen(row.original, 1)}
                        style={{
                          color: `${colors.red}`,
                          fontWeight: '700',
                          paddingTop: ' 5px',
                          paddingBottom: ' 5px',
                          marginBottom: '10px',
                        }}
                      />
                    </>
                  )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.DELETE_SHORT_TERM_MODEL,
          permissions.RUN_SHORT_TERM_MODEL,
          permissions.VIEW_SHORT_TERM_MODEL,
        ]),
      },
    ],
    [
      appUser,
      clientId,
      handleDownload,
      handleDeleteDialogOpen,
      handleEditAndRerun,
      handleSetStepToRun,
    ]
  );
  return (
    <TableContainer padding='0rem'>
      <MakeTable
        appUser={appUser}
        data={stepOneAnalysis}
        columns={columns}
        isFetching={isFetching}
        tableTitle='Step 1 Analysis'
        rangeFilter={rangeFilter}
        rangeFilterName='Created At'
        resetInitialFilter={resetInitialFilter}
        handleCheckAccountValidation={handleCheckAccountValidation}
        handleOpenRunAnalysisDialog={handleOpenRunAnalysisDialog}
        handleSetStepToRun={handleSetStepToRun}
        initialState={{
          sortBy: [{ id: 'createdAt', desc: true }]
        }}
        {...props}
      />
    </TableContainer>
  );
};

export default StepOneAnalysisTable;
