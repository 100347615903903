import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Icon, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import shortid from 'shortid';
import colors from '../../utils/colors';
import {
  checkPermissions,
  getMonthDifference,
  localDateFormatter,
  monthYearOnly,
} from '../../utils/functions';
import iconValue from '../../utils/icons';
import permissions from '../../utils/permissions';

import {
  ActionIconButton,
  StarIcon,
  StyleColumnCell,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import { TableContainer } from './components/table-styled-components';
import CashVestScoresBreakdown from '../../containers/Analysis/CashVestScoresBreakdown';

const CashVestHistoryTable = ({
  appUser,
  cashVestScores,
  isFetching,
  handleOpenEditDialog,
  ...props
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    // if two or more rows then set selectedRows as an array containing the first two rows.
    // this will allow us to auto-select the first two rows.
    if (cashVestScores.length >= 2) {
      setSelectedRows([cashVestScores[0], cashVestScores[1]]);
      // if one row, automatically select the row.
    } else if (cashVestScores.length === 1)
      setSelectedRows([cashVestScores[0]]);
    else setSelectedRows([]);
  }, [setSelectedRows, cashVestScores]);

  const deleteSelectedRow = useCallback(
    (id) => {
      const index = selectedRows.findIndex((item) => item.cashVestId === id);
      selectedRows.splice(index, 1);
    },
    [selectedRows]
  );

  // up to two boxes can be selected at a time.
  const handleSetSelectedRows = useCallback(
    (row) => {
      let selectedIds = selectedRows.map((item) => item.cashVestId);
      // if box is already selected, de-select the box
      if (selectedIds.includes(row.cashVestId)) {
        deleteSelectedRow(row.cashVestId);
        // if two boxes are selected, de-select the oldest checked box,
        // and add the box just clicked as the most recently selected box.
      } else if (selectedRows.length === 2) {
        selectedRows.shift();
        selectedRows.push(row);
      } else {
        // Only one item is currently checked, so check the row just clicked also.
        selectedRows.push(row);
      }
      setSelectedRows([...selectedRows]);
    },
    [selectedRows, deleteSelectedRow]
  );

  const handleDeleteContainer = (row) => {
    deleteSelectedRow(row.cashVestId);
    setSelectedRows([...selectedRows]);
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'createdAt',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        Cell: ({ cell: { value } }) => localDateFormatter(value),
        isVisible: true,
      },
      {
        Header: () => 'Date Range',
        id: 'dateRange',
        width: '30%',
        isVisible: true,
        accessor: (row) => {
          return row.startDate && row.endDate
            ? `${getMonthDifference(
              row.startDate,
              row.endDate
            )} Months (${monthYearOnly(row.startDate)} - ${monthYearOnly(
              row.endDate
            )})`
            : 'Edit to Enter the Valid Date Range';
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Score' />
        ),
        accessor: 'totalScore',
        isVisible: true,
        width: '15%',
        filter: 'numberRange',
        Cell: ({ cell: { value }, row }) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyleColumnCell color={colors.boldText}>{value}</StyleColumnCell>
              <StarIcon
                starPercentage={`${row.original.totalStar}%`}
                style={{ paddingLeft: '10px' }}
              />
            </div>
          );
        },
      },
      {
        Header: () => 'Viewing',
        id: 'viewing',
        width: '15%',
        Cell: ({ row }) => {
          let selectedIds = selectedRows.map((item) => item.cashVestId);

          return (
            <RowSelector
              onClick={() => {
                handleSetSelectedRows(row.original);
              }}
              isViewingColumn={true}
              {...row.getToggleRowSelectedProps()}
              isSelected={selectedIds.includes(row.original.cashVestId)}
            />
          );
        },
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                <MenuItem
                  text='Edit'
                  onClick={() => handleOpenEditDialog(row.original)}
                  style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                />
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.EDIT_CASH_VEST_SCORES,
        ]),
      },
    ],
    [selectedRows, handleSetSelectedRows, handleOpenEditDialog, appUser]
  );
  return (
    <>
      <TableContainer padding='0rem'>
        <MakeTable
          data={cashVestScores}
          columns={columns}
          isFetching={isFetching}
          tableTitle='cashVest Score History'
          selectedRows={selectedRows}
          isViewableColumnTable={true}
          initialState={{
            sortBy: [{ id: 'createdAt', desc: true }]
          }}
          {...props}
        />
      </TableContainer>
      {selectedRows.map((row) => {
        return (
          <CashVestScoresBreakdown
            appUser={appUser}
            selectedRow={row}
            key={shortid.generate()}
            handleDeleteContainer={handleDeleteContainer}
            handleOpenEditDialog={handleOpenEditDialog}
          />
        );
      })}
    </>
  );
};

export default CashVestHistoryTable;
