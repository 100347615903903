import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ComponentBody } from '../../components/styled-components';
import { fetchAllBanks, fetchAllowedDataGatheringValues } from '../../actions/banks';
import BankTable from '../../components/table/BankTable';
import { createBank, updateBank } from '../../api/banks';
import { Intent } from '@blueprintjs/core';
import BanksDialogBox from '../../components/dialog/BanksDialogBox';
import AppToast from '../../components/Toast';
import BankHeader from '../../components/headers/BankHeader';
import SameNameWarning from '../../components/dialog/SameNameWarning';
import { checkSameName } from '../../utils/functions';

const BankManagementBase = ({
  appUser,
  banks,
  allowedDataGatheringValues,
  isAllBanksFetching,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedBank, setSelectedBank] = useState({});
  const [bankName, setBankName] = useState('');
  const [assetSize, setAssetSize] = useState('');
  const [status, setStatus] = useState(1);
  const [isTryingToSubmit, setIsTryingToSubmit] = useState(false);
  const [isOverWriting, setIsOverWriting] = useState(false);
  const [createdBank, setCreatedBank] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedBankId, setEditedBankId] = useState('');
  const [initialBankName, setInitialBankName] = useState('');
  const [bankId, setBankId] = useState(undefined);
  const [defaultDataGatheringFrequency, setDefaultDataGatheringFrequency] = useState(undefined);
  const [dataAvailabilityDate, setDataAvailabilityDate] = useState(undefined);
  const [fdicCode, setFdicCode] = useState('');
  const bankNames = banks.map((bank) => bank.bankName.toLowerCase());
  const allowedDataAvailabilityDates = allowedDataGatheringValues && allowedDataGatheringValues.allowedDataAvailabilityDates;
  const { fetchAllBanks, fetchAllowedDataGatheringValues, fetchBankNames } = props;

  useEffect(() => {
    fetchAllBanks();
    fetchAllowedDataGatheringValues();
  }, [fetchAllBanks, fetchBankNames, fetchAllowedDataGatheringValues]);

  useEffect(() => {
    if (selectedBank) {
      setBankName(selectedBank.bankName || '');
      setAssetSize(selectedBank.assetSize || '');
      setStatus(selectedBank.status >= 0 ? selectedBank.status : 1);
      setBankId(selectedBank.bankId || undefined);
      setDefaultDataGatheringFrequency(selectedBank.defaultDataGatheringFrequency || undefined);
      setDataAvailabilityDate(selectedBank.dataAvailabilityDate || undefined);
      setFdicCode(selectedBank.fdicCode || '');
    }
  }, [selectedBank]);

  const openAddNewBankDialog = () => {
    setTitle('Add New Bank');
    setIsOpen(true);
  };

  const openEditBankDialog = (attributes) => {
    setSelectedBank(attributes);
    setTitle('Edit Bank');
    setInitialBankName(attributes.bankName);
    setIsOpen(true);
  };

  const closeDialog = () => {
    handleWarningDialogClose();
    setIsTryingToSubmit(false);
    setTitle('');
    setStatus(1);
    setAssetSize('');
    setIsEditing(false);
    setIsOpen(false);
    setCreatedBank({});
    setEditedBankId('');
    setBankName('');
    setSelectedBank({});
    setDefaultDataGatheringFrequency(undefined);
    setDataAvailabilityDate(undefined);
    setFdicCode('');
  };

  const handleCreate = (bank) => {
    createBank(bank)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAllBanks();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create bank.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        closeDialog();
      });
  };

  const handleCreateBank = async (values) => {
    // back end is expecting addBankName and addAssetSize
    // these values are transformed in the bank form from bankName and assetSize
    const bank = {
      ...values,
    };
    setIsTryingToSubmit(true);

    const isSameName = checkSameName(bank.addBankName, bankNames);
    if (isSameName) {
      handleWarningDialogOpen(bank);
      setBankName(bank.addBankName);
    } else {
      handleCreate(bank);
    }
  };

  const handleUpdate = (bank, bankId) => {
    updateBank(bankId, bank)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAllBanks();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit bank.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        closeDialog();
      });
  };

  const handleEditBank = async (values) => {
    const bank = {
      ...values,
    };
    setIsTryingToSubmit(true);
    if (
      checkSameName(bank.bankName, bankNames) &&
      initialBankName !== bank.bankName
    ) {
      setIsEditing(true);
      handleWarningDialogOpen(bank, bankId);
      setBankName(bank.bankName);
    } else {
      handleUpdate(bank, bankId);
    }
  };

  const handleWarningDialogOpen = (bank, bankId) => {
    setIsWarningDialogOpen(true);
    setCreatedBank(bank);
    setEditedBankId(bankId);
  };

  const handleWarningDialogClose = () => {
    setIsOverWriting(false);
    setIsWarningDialogOpen(false);
  };

  const handleIgnoreSameName = () => {
    setIsOverWriting(true);
    if (isEditing) {
      handleUpdate(createdBank, editedBankId);
    } else {
      handleCreate(createdBank);
    }
  };

  return (
    <>
      <BankHeader
        appUser={appUser}
        header={'Banks'}
        hasButton={true}
        buttonText={'Add New Bank'}
        action={openAddNewBankDialog}
        {...props}
      />
      <ComponentBody>
        <BankTable
          appUser={appUser}
          banks={banks}
          isFetching={isAllBanksFetching}
          openEditBankDialog={openEditBankDialog}
          {...props}
        />
        <BanksDialogBox
          isOpen={isOpen}
          title={title}
          isTryingToSubmit={isTryingToSubmit}
          closeDialog={closeDialog}
          bankName={bankName}
          assetSize={assetSize}
          status={status}
          defaultDataGatheringFrequency = {defaultDataGatheringFrequency}
          dataAvailabilityDate = {dataAvailabilityDate}
          allowedDataAvailabilityDates = {allowedDataAvailabilityDates}
          fdicCode = {fdicCode}
          handleCreateBank={handleCreateBank}
          handleEditBank={handleEditBank}
          appUser={appUser}
          bankId={bankId}
        />
        <SameNameWarning
          parentTitle={'Bank'}
          isOpen={isWarningDialogOpen}
          handleWarningDialogClose={handleWarningDialogClose}
          handleIgnoreSameName={handleIgnoreSameName}
          warningName={bankName}
          isOverwriting={isOverWriting}
        />
      </ComponentBody>
    </>
  );
};

const mapStateToProps = (state) => ({
  banks: state.banks.allBanks,
  isAllBanksFetching: state.banks.isAllBanksFetching,
  allowedDataGatheringValues: state.banks.allowedDataGatheringValues,
});

const BankManagement = connect(mapStateToProps, {
  fetchAllBanks,
  fetchAllowedDataGatheringValues,
})(BankManagementBase);

export default BankManagement;
